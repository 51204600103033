import React, { useState, useRef } from "react";
import {Buffer} from 'buffer';
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import {
  useLocation,
  useHistory
} from "react-router-dom";
import { IonContent, IonPage, IonButtons,
  IonButton,
  IonHeader,
  IonFooter,
  IonToolbar,
  IonTextarea,
  IonTitle,
  IonItem,
  IonLabel,
  IonBadge,
  IonInput,
  IonIcon,
  useIonToast,
  IonDatetime,
  IonDatetimeButton,
  useIonLoading,
  IonModal, IonList, IonSelect, IonSelectOption, useIonAlert, IonCard, IonCardContent } from '@ionic/react';
import { refreshOutline, download, gridSharp, closeOutline, addOutline } from 'ionicons/icons';
import axios from 'axios';
import { Excalidraw, MainMenu, Footer, exportToCanvas } from "@excalidraw/excalidraw";
import './Draw.css';

const API_KEY = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoid2ViYXBwIiwibmFtZSI6IndlYmFwcCIsIkFQSV9USU1FIjoxNjc1MzU3MTExfQ.L3E5pQhhMF5LecrkC65tbY6gKJ7l-abkGx5k8Kf5wO0';
const API_URL = 'https://new.cyberbox-chic.com/api';


const s3Client = new S3Client({
    endpoint: "https://sgp1.digitaloceanspaces.com", // Find your endpoint in the control panel, under Settings. Prepend "https://".
    forcePathStyle: false, // Configures to use subdomain/virtual calling format.
    region: "us-east-1", // Must be "us-east-1" when creating new Spaces. Otherwise, use the region in your endpoint (e.g. nyc3).
    credentials: {
      accessKeyId: "DO00KJ7B2J9CGLDGQJE9", // Access key pair. You can create access key pairs using the control panel or API.
      secretAccessKey: "fB4betP06SFvHXSY7453kxIUJ4+CXRBD5d5VgZDcN6c" // Secret access key defined through an environment variable.
    }
});




const uploadObject = async (fileName: any, fileBody: any) => {

  const base64Data = new (Buffer as any).from(fileBody.replace(/^data:image\/\w+;base64,/, ""), 'base64')
  const type = fileBody.split(';')[0].split('/')[1]

  const params = {
    Bucket: "files.cyberbox-chic.com", // The path to the directory you want to upload the object to, starting with your Space name.
    Key: `${fileName}.${type}`, // Object key, referenced whenever you want to access this file later.
    Body: base64Data, // The object's contents. This variable is an object, not a string.
    ACL: "public-read", // Defines ACL permissions, such as private or public.
    // ContentEncoding: 'base64',
    ContentType: `image/${type}`
  };

  try {
    const data = await s3Client.send(new PutObjectCommand(params));
    // console.log(
    //   "Successfully uploaded object: " +
    //     params.Bucket +
    //     "/" +
    //     params.Key
    // );
    return params.Key;
  } catch (err) {
    console.log("Error", err);
  }
};

const uploadFile = async (fileName: any, fileBody: any) => {

  // const base64Data = new (Buffer as any).from(fileBody.replace(/^data:image\/\w+;base64,/, ""), 'base64')
  // const type = fileBody.split(';')[0].split('/')[1]

  const params = {
    Bucket: "files.cyberbox-chic.com", // The path to the directory you want to upload the object to, starting with your Space name.
    Key: `${fileName}.json`, // Object key, referenced whenever you want to access this file later.
    Body: fileBody, // The object's contents. This variable is an object, not a string.
    ACL: "public-read", // Defines ACL permissions, such as private or public.
    // ContentEncoding: 'base64',
    ContentType: 'application/json'
  };

  try {
    const data = await s3Client.send(new PutObjectCommand(params));
    // console.log(
    //   "Successfully uploaded object: " +
    //     params.Bucket +
    //     "/" +
    //     params.Key
    // );
    return params.Key;
  } catch (err) {
    console.log("Error", err);
  }
};

const Draw: React.FC = () => {
  const [presentAlert] = useIonAlert();
  const [viewModeEnabled] = useState(false);
  const [zenModeEnabled] = useState(false);
  const [gridModeEnabled, setGridModeEnabled] = useState(false);
  const [presentToast] = useIonToast();

  const [isOpen, setIsOpen] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [staffs, setstaffs] = useState([]);
  const [title, setTitle] = useState<any>('');
  const [comment, setComment] = useState<any>('');
  const [tasks, setTasks] = useState([]);
  const [taskTitle, setTaskTitle] = useState<any>('');
  // const [createTime, setCreateTime] = useState('');
  const createTime = useRef<string>('');
  const [currentCustomer, setCurrentCustomer] = useState('');
  const [selectedStaff, setSelectedStaff] = useState('');
  // const [canvasUrl, setCanvasUrl] = useState("");
  const canvasUrl = useRef<string>("")
  const [excalidrawAPI, setExcalidrawAPI] = useState<any>(null);
  const [saving, setSaving] = useState('Save to server');
  const [canNotSave, setCanNotSave] = useState(false);

  const [presentingElement, setPresentingElement] = useState<HTMLElement | null>(null);
  const page = useRef(null);

  const [drawingsID, setDrawingsID] = useState<number>(0);
  const [filesID, setFilesID] = useState<number>(0);
  const [isTyping, setIsTyping] = useState(false);
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  const [present, dismiss] = useIonLoading();
  const [taskList, setTaskList] = useState<any>([{
    title: ''
  }]);

  

  const myInfo: any = JSON.parse(window.localStorage.getItem("AUTH") as string);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let history = useHistory()

  React.useEffect(() => {
    setPresentingElement(page.current);
    axios.get(API_URL+'/customers', {
      headers: {
        'authtoken': API_KEY,
      }
    }).then((response) => {
      setCustomers(response.data);
    });

    axios.get(API_URL+'/staffs', {
      headers: {
        'authtoken': API_KEY,
      }
    }).then((response) => {
      setstaffs(response.data);
    });

    window.addEventListener('ionKeyboardDidShow', (ev: any) => {
      setIsTyping(true)
      setKeyboardHeight(ev.detail.keyboardHeight)
    });
    window.addEventListener('ionKeyboardDidHide', () => {
      setIsTyping(false) 
      setKeyboardHeight(0)
    });
  }, []);

  React.useEffect(() => {
    if(query.get("id") && excalidrawAPI) {
      insertDrawing(Number(query.get("id")))
    }
    // console.log(query.get("id"))
  }, [excalidrawAPI, query]);


  const resetData = () => {
    excalidrawAPI.resetScene();
    setCurrentCustomer('');
    setTitle('');
    setTaskTitle('');
    setComment('');
    setSelectedStaff('');
    setIsOpen(false);
    setCanNotSave(false);
    setTasks([]);
    setSaving('Save to server');
    // setCanvasUrl("");
    setDrawingsID(0);
    setTaskList([]);
    createTime.current = '';
    canvasUrl.current = '';
  }

  const handleReset = () => {
    presentAlert({
      header: 'Reset canvas?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {
            resetData();
            history.push('/home')
          },
        },
      ],
    })
  }

  const addTaskBtn = () => {
    setTaskList((oldArray: any) => [...oldArray, {
      title: ''
    }])
    // console.log(taskList)
  }

  const closeTaskBtn = (index: any) => {
    // setTaskList((oldArray: any) => [...oldArray, {
    //   title: ''
    // }])
    let newArray = [...taskList]
    console.log(newArray)
    newArray.splice(index, 1)

    console.log(newArray)
    setTaskList(newArray)
  }

  const updateTaskArray = (data: any, index: any, type: any) => {
    // console.log(data)
    // console.log(index)
    let newArray = [...taskList]
    if (type === 'title') {
      newArray[index].title = data
    } else if (type === 'staff') {
      newArray[index].staff = data
    } else if (type === 'comment') {
      newArray[index].comment = data
    }
    
    // console.log(newArray)
    setTaskList(newArray)
  }

  const taskArrayList = taskList.map((task: any, index: any) =>
    <IonCard className="ion-no-margin ion-margin-vertical" key={index}>
      <IonCardContent>
        <IonItem>
          <IonLabel>New Task {index + 1}</IonLabel>
          {/* <IonIcon icon={closeOutline} onClick={async () => closeTaskBtn(index)} slot="end"></IonIcon> */}
        </IonItem>
        <IonItem>
          <IonLabel position="stacked">
            <h2>Title</h2>
          </IonLabel>
          <IonInput value={task.title} onIonChange={ev => updateTaskArray(ev.target.value, index, 'title')} placeholder="Type something here"></IonInput>
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">
            <h2>Mention</h2>
          </IonLabel>
          <IonSelect value={task.staff} onIonChange={ev => updateTaskArray(ev.detail.value, index, 'staff')} placeholder="Select Staff" multiple={true}>
            {
              staffs.map((staff: any) =>
                  <IonSelectOption value={staff.staffid} key={staff.staffid}>{staff.firstname + ' ' + staff.lastname}</IonSelectOption>
                )
            }
          </IonSelect>
        </IonItem>

        <IonItem>
          <IonLabel position="stacked">
            <h2>Description</h2>
          </IonLabel>
          <IonTextarea value={task.comment} onIonChange={ev => updateTaskArray(ev.target.value, index, 'comment')} placeholder="Type something here"></IonTextarea>
        </IonItem>
      </IonCardContent>
    </IonCard>
  )


  const insertDrawing = async (drawing_id: number) => {
    resetData()
    present({
      message: 'Loading...please wait.',
    })
    await axios.get(API_URL+'/drawing/'+drawing_id, {
      headers: {
        'authtoken': API_KEY,
      }
    }).then(async (response) => {
      // setDrawings(response.data);
      const data = response.data;
      console.log(data.elements_files)
      setDrawingsID(data.id)
      setFilesID(data.elements_files.replace(/.json/g, ''))
      setTitle(data.title)
      setCurrentCustomer(data.customer_id)
      setTasks(data.tasks)
      createTime.current = new Date(new Date(data.create_time).getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString();
      
      if(data.data_space) {
        let response = await fetch(`https://files.cyberbox-chic.com/${data.elements}`);
        // files = [await response.json()];
        const sceneData = {
          elements: await response.json(),
        };
        // console.log(files)
        excalidrawAPI.updateScene(sceneData);
      }else{
        const sceneData = {
          elements: data.data_space ? '' : JSON.parse(data.elements),
        };

        excalidrawAPI.updateScene(sceneData);
      }

      
      let files: any[] = []
      if(data.files_space) {
        let response = await fetch(`https://files.cyberbox-chic.com/${data.elements_files}`);
        files = [await response.json()];
        // console.log(files)
      }else{
        files = [JSON.parse(data.elements_files)];
      }

      // eslint-disable-next-line array-callback-return
      files.map((item: any, index: number) => {
        
        Object.values(item).map((d: any) => {
          // d.dataURL = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgmyrX47GADc9QujQlf_b7Vp0xnPtEoat35jjqifZ8&s"
          // console.log(d)
          excalidrawAPI.addFiles([d])
        });
        // excalidrawAPI.addFiles([Object.values(item)[0]]);
      })
      

      dismiss();
      presentToast({
        message: 'Open drawing success!',
        position: 'top',
        cssClass: 'successToast',
        duration: 2500,
        icon: download
      })
    })
  }


  const handleSave = async () => {
    // setCanvasUrl('');
    canvasUrl.current = ""

    if (!currentCustomer || !currentCustomer.length) {
      presentToast({
        message: 'Empty data, please input customer informations!',
        position: 'top',
        duration: 1500,
        cssClass: 'errorToast',
        icon: closeOutline
      })
      return
    }
    
    if (!excalidrawAPI) {
      return
    }
    const elements = excalidrawAPI.getSceneElements();
    if (!elements || !elements.length) {
      presentToast({
        message: 'Empty data, please draw and input customer informations!',
        position: 'top',
        duration: 1500,
        cssClass: 'errorToast',
        icon: closeOutline
      })
      return
    }


    const canvas = await exportToCanvas({
      elements,
      maxWidthOrHeight: 800,
      appState: {
        // ...initialData.appState,
        exportWithDarkMode: false,
      },
      files: excalidrawAPI.getFiles(),
    });
    // console.log(excalidrawAPI.getFiles())
    // setCanvasUrl(canvas.toDataURL('image/jpeg', 0.6));
    // console.log(canvas)
    canvasUrl.current = canvas.toDataURL('image/jpeg', 0.5)

    

    // console.log(selectedStaff)
    
    if (canvasUrl.current.length > 0 && currentCustomer.length > 0) {
      setCanNotSave(true)
      setSaving('Uploading Photos...')
      if(createTime.current === '') {
        createTime.current = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString()
      }

      const filesTime = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString()

      // uploadObject()
      // let files = [excalidrawAPI.getFiles()]
      // await Promise.all(files.map(async (item: any) => {
      //   await Promise.all(Object.values(item).map(async (d: any) => {
      //     // console.log(d);
      //     if (!d.dataURL.startsWith("https")) {
      //       const res = await uploadObject(d.id, d.dataURL);
      //       // console.log(res);
      //       d.dataURL = `https://files.cyberbox-chic.com.sgp1.cdn.digitaloceanspaces.com/${res}`;
      //     }
      //     return d;
      //   }));
      //   return item;
      // }));
      // console.log(excalidrawAPI.getFiles())

      const elements_json = await uploadFile('elements' + filesTime.replace(/[^a-zA-Z0-9]/g, ''), JSON.stringify(elements))
      const elements_files = await uploadFile('files' + filesTime.replace(/[^a-zA-Z0-9]/g, ''), JSON.stringify(excalidrawAPI.getFiles()))
      const cover = await uploadObject(filesTime.replace(/[^a-zA-Z0-9]/g, ''), canvasUrl.current);
      const data = {
          "drawing_id": drawingsID,
          "title": title,
          "customer_id": currentCustomer,
          "drawing": cover,
          "create_time": createTime.current,
          "create_by": drawingsID === 0 ? myInfo.staff_user_id : '',
          "update_by": myInfo.staff_user_id,
          "elements": elements_json,
          "elements_files": elements_files,
      };
      // console.log(typeof(createTime.current.split('T')[0]));

      
      // console.log(data.elements)
      // console.log(data)
      setSaving('Saving Data...')

      axios.post(API_URL+'/drawing', data, {
          headers: {
            'authtoken': API_KEY,
            'Content-Type': 'multipart/form-data',
          }
      }).then((response) => {
        // console.log(response);
        if(taskList.length > 0) {
          taskList.forEach((data: any) => {
            if(data.title && data.staff) {
              axios.post(API_URL+'/tasks', 
                {
                  "name": data.title,
                  "startdate": new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().split('T')[0],
                  "priority": 2,
                  "billable": '0',
                  "assignees": data.staff,
                  "description": data.comment,
                  "rel_type": "drawing",
                  "rel_id": response.data.id,
                }, 
                {
                  headers: {
                    'authtoken': API_KEY,
                    'Content-Type': 'multipart/form-data',
                  }
                })
            }
          })
        }

        resetData();
        presentToast({
          message: 'Publish!',
          position: 'top',
          cssClass: 'successToast',
          duration: 1500,
          icon: download
        })
        history.push('/home')
        
      }).catch((error) => {
          presentToast({
            message: 'Save Error!',
            position: 'top',
            cssClass: 'errorToast',
            duration: 1500,
            icon: download
          })

          setCanNotSave(false);
          setSaving('Save to server');
      });
    }else{
      presentToast({
        message: 'Try again!',
        position: 'top',
        cssClass: 'errorToast',
        duration: 1500,
        icon: download
      })
    }
  }

  return (
    <IonPage ref={page}>
      <IonContent fullscreen scroll-y="false">
        <Excalidraw
          ref={(api) => setExcalidrawAPI(api)}
          initialData={{
            appState: { theme: 'light' },
            scrollToContent: true,
            // files: [],
          }}
          viewModeEnabled={viewModeEnabled}
          zenModeEnabled={zenModeEnabled}
          gridModeEnabled={gridModeEnabled}
          UIOptions={{ welcomeScreen: false }}

          renderTopRightUI={() => {
            return (
              <div className="top-right-btn ion-hide-sm-down">
                <IonButton expand="block" fill="outline" color="medium" className="ion-no-margin" onClick={handleReset}>
                    <IonIcon icon={refreshOutline}></IonIcon>
                </IonButton>
                <IonButton expand="block" className="ion-no-margin" color="primary" onClick={() => setIsOpen(true)}>
                    <IonIcon icon={download}></IonIcon>
                </IonButton>
              </div>
            );
          }}
        >
          <MainMenu>
            <MainMenu.Item onSelect={() => setGridModeEnabled(!gridModeEnabled)}>
              <IonIcon icon={gridSharp}></IonIcon> Grid Mode
            </MainMenu.Item>
            <MainMenu.Item onSelect={handleReset}>
              <IonIcon icon={refreshOutline}></IonIcon> Reset Canvas
            </MainMenu.Item>
            <MainMenu.Item onSelect={() => setIsOpen(true)}>
              <IonIcon icon={download}></IonIcon> Save
            </MainMenu.Item>
          </MainMenu>
          <Footer>
            {/* {currentCustomer} */}
          </Footer>
        </Excalidraw>
        <IonModal isOpen={isOpen} backdropDismiss={false} presentingElement={presentingElement!}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Save Drawing</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent class="ion-padding">
            <h4>Customer Informations</h4>
            <IonCard className="ion-no-margin ion-margin-vertical">
              <IonCardContent>
                <IonList>
                  <IonItem>
                    <IonLabel position="stacked">
                      <h2>Title</h2>
                    </IonLabel>
                    <IonInput value={title} onIonChange={ev => setTitle(ev.target.value)}></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">
                      <h2>Customer</h2>
                    </IonLabel>
                    <IonSelect interface="action-sheet" value={currentCustomer} placeholder="Select Customer" onIonChange={ev => setCurrentCustomer(ev.detail.value)}>
                      {
                        customers.map((customer: any) =>
                            <IonSelectOption value={customer.userid} key={customer.userid}>{customer.company}</IonSelectOption>
                          )
                      }
                    </IonSelect>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">
                      <h2>Date</h2>
                    </IonLabel>
                    <IonDatetimeButton datetime="datetime" class="ion-justify-content-start ion-margin-vertical"></IonDatetimeButton>
                  </IonItem>
                </IonList>
                
              </IonCardContent>
            </IonCard>
            
            <>
              
              <IonModal keepContentsMounted={true}>
                <IonDatetime id="datetime" locale="en-HK" value={createTime.current} onIonChange={(e: any) => {createTime.current = e.detail.value}}></IonDatetime>
              </IonModal>
            </>
            <h4>Tasks</h4>
            <IonList inset={true} lines="full">
              {
                tasks.map((task: any) =>
                    <IonItem key={task.id} button detail={true} onClick={() => window.open('https://new.cyberbox-chic.com/admin/tasks/view/'+task.id, '_blank')}>
                      <IonLabel>
                        <h3>Task: {task.name}</h3>
                      </IonLabel>
                      <IonBadge color={task.status === "5" ? 'success' : 'warning'}>{task.status === "5" ? 'Finished' : 'Not finish'}</IonBadge>
                    </IonItem>
                  )
              }
            </IonList>
            
            <div className="task-header">
              <h4>Create Task</h4>
              <IonButton shape="round" size="small" onClick={addTaskBtn}><IonIcon icon={addOutline}></IonIcon>More task</IonButton>
            </div>
            
            {
              taskArrayList
            }

            
            {/* <IonCard className="ion-no-margin ion-margin-vertical">
              <IonCardContent>
                <IonItem>
                  <IonLabel position="stacked">
                    <h2>Title</h2>
                  </IonLabel>
                  <IonInput value={taskTitle} onIonChange={ev => setTaskTitle(ev.target.value)} placeholder="Type something here"></IonInput>
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">
                    <h2>Mention</h2>
                  </IonLabel>
                  <IonSelect value={selectedStaff} placeholder="Select Staff" onIonChange={ev => setSelectedStaff(ev.detail.value)} multiple={true}>
                    {
                      staffs.map((staff: any) =>
                          <IonSelectOption value={staff.staffid} key={staff.staffid}>{staff.firstname + ' ' + staff.lastname}</IonSelectOption>
                        )
                    }
                  </IonSelect>
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked">
                    <h2>Description</h2>
                  </IonLabel>
                  <IonTextarea value={comment} onIonChange={ev => setComment(ev.target.value)} placeholder="Type something here"></IonTextarea>
                </IonItem>
                
              </IonCardContent>
            </IonCard> */}

            <div style={{height: `${keyboardHeight}px`, width: '100%', display: `${isTyping ? 'block' : 'none'}`}}></div>
            
          </IonContent>
          <IonFooter>
            <IonButton className="ion-margin" expand="block" disabled={canNotSave} onClick={handleSave}>{saving}</IonButton>
          </IonFooter>
        </IonModal>

      </IonContent>
    </IonPage>
  );
};

export default Draw;
