import React, { useState, useEffect } from 'react';
import { IonContent, IonPage, IonItem, IonLabel, IonInput, IonRow, IonCol, IonButton, IonImg, IonFooter, IonNote } from '@ionic/react';
import { RouteComponentProps } from "react-router";
import { useAuth } from "./auth/authContext";
import './Login.css';

const Login: React.FC<RouteComponentProps> = ({ history }) => {

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [logo, setLogo] = useState<string>('https://new.cyberbox-chic.com/uploads/company/2111b7bc2c6ce791995a709217def684.png');

  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState<boolean>();
  const [isPWValid, setIsPWValid] = useState<boolean>();

  const { logIn } = useAuth()!;

  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

  // if (prefersDark.matches) {
  //   setLogo('https://crm.cyberbox-chic.com/uploads/company/2111b7bc2c6ce791995a709217def684.png');
  // } 

  const validateEmail = (email: string) => {
    return email.match(
      /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    );
  };

  const validate = (ev: Event) => {
    const value = (ev.target as HTMLInputElement).value;

    setIsValid(undefined);

    if (value === '') return;

    validateEmail(value) !== null ? setIsValid(true) : setIsValid(false);
  };

  const markTouched = () => {
    setIsTouched(true);
  };

  const handleLogin = async (e: any) => {
		e.preventDefault();
    if (!email) {
      setIsValid(false);
      return;
    }
    
    if (!validateEmail(email)) {
      setIsValid(false);
      return;
    }

    if (!password || password.length < 6) {
      setIsPWValid(false);
      return;
    } else {
      setIsPWValid(true);
    }

		try {
			await logIn(email, password);
			// history.push('/');
      // console.log(a)
		} catch (error) {
			console.error('error', error);
		}
	};

  prefersDark.addListener((mediaQuery) => {
    if (mediaQuery.matches) {
      setLogo('/assets/icon/login-light.png');
    } else {
      setLogo('/assets/icon/login-dark.png');
    }
  });

  useEffect(() => {
    if (prefersDark.matches) {
      setLogo('/assets/icon/login-light.png');
    } else {
      setLogo('/assets/icon/login-dark.png');
    }
     
  }, [prefersDark.matches]);
  return (
    <IonPage>
      <IonContent fullscreen className="ion-padding" scroll-y="false">
        <IonRow class="ion-justify-content-center">
          <IonCol size="12" size-md="8" size-lg="3">
            <IonImg class="login-logo" src={logo} alt="Cyberbox-chic"></IonImg>
          </IonCol>
        </IonRow>
        <IonRow class="ion-justify-content-center">
          <IonCol size="12" size-md="8" size-lg="6">
            <IonItem fill="solid" className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`}>
              <IonLabel position="floating">Email</IonLabel>
              <IonInput type="email" value={email} onIonInput={(event) => validate(event)} onIonBlur={() => markTouched()} onIonChange={(e) => setEmail(e.detail.value!)}></IonInput>
              <IonNote slot="error">Invalid email</IonNote>
            </IonItem>
            <IonItem fill="solid" className={`${isPWValid && 'ion-valid'} ${isPWValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`}>
              <IonLabel position="floating">Password</IonLabel>
              <IonInput type="password" value={password} onIonChange={(e) => setPassword(e.detail.value!)}></IonInput>
              <IonNote slot="error">Invalid</IonNote>
            </IonItem>
            {/* <IonItem lines="full">
              <IonLabel position="floating">Password</IonLabel>
              <IonInput
                type="password"
                value={password}
                onIonChange={(e) => setPassword(e.detail.value!)}
                >
              </IonInput>
            </IonItem> */}
          </IonCol>
        </IonRow>
        <IonRow class="ion-justify-content-center">
          <IonCol size="12" size-md="8" size-lg="6">
            <IonButton expand="block" onClick={handleLogin}>Sign In</IonButton>
          </IonCol>
        </IonRow>
      </IonContent>
      <IonFooter className="ion-padding">
        <IonRow class="ion-justify-content-center">
          <IonCol class="ion-text-center" size="12">
            {process.env.REACT_APP_VERSION}
          </IonCol>
        </IonRow>
      </IonFooter>
    </IonPage>
  );
};

export default Login;
